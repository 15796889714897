import {
  createManyWorkspaceMembership,
  createManyWsAccount,
  createManyWsCommandAliases,
  createManyWsEvent,
  createManyWsFeed,
  createManyWsFeedGroup,
  createManyWsFeedGroupMembership,
  createManyWsItem,
  createManyWsPermission,
  createManyWsTemplate,
} from "@/data/pg/bulkInserts";
import { db } from "@/db/db";
import { workspaceMembership } from "@/db/schema";
import { AppContext } from "@/models/AppStateProvider";
import { logger } from "@/utils/logging";
import { and, eq } from "drizzle-orm";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type {
  BootstrapLatestUnreadEventPerFeed,
  BootstrapWorkspaceAccounts,
  BootstrapWorkspaceFeedGroupMemberships,
  BootstrapWorkspaceFeedGroups,
  BootstrapWorkspaceFeedItems,
  BootstrapWorkspaceFeedPermissions,
  BootstrapWorkspaceFeeds,
  BootstrapWorkspaceMemberships,
  BootstrapWorkspaceTemplates,
  WorkspaceMembership,
  WsAccount,
  WsCommandAlias,
  WsEvent,
  WsFeed,
  WsFeedGroup,
  WsFeedGroupMembership,
  WsItem,
  WsPermission,
  WsTemplate,
} from "../../web-client/api/data-contracts";
import type Client from "../../web-client/client";
import { MyAccountContext } from "./StateProviders/myAccountProvider";
import { TelemetryContext, actions } from "./TelemetryProvider";
import { fetchFeedItemsAndDownloadEvents } from "./actions/bootstrap";
import {
  initializeFeedLatestActivity,
  initializeFromAlias,
  initializeIsAliasFeed,
  initializeUnreadsForAllFeeds,
} from "./actions/initialFeedLoad";

const waitForWritesBeforeFetchingNextPage = false;

export type BootstrapResponses =
  | BootstrapWorkspaceFeedItems
  | BootstrapWorkspaceMemberships
  | BootstrapWorkspaceFeedPermissions
  | BootstrapWorkspaceFeeds
  | BootstrapWorkspaceTemplates
  | BootstrapWorkspaceAccounts
  | BootstrapWorkspaceFeedGroups
  | BootstrapWorkspaceFeedGroupMemberships
  | BootstrapLatestUnreadEventPerFeed;

export type CreateManyPayloads =
  | WorkspaceMembership[]
  | WsItem[]
  | WsPermission[]
  | WsFeed[]
  | WsTemplate[]
  | WsAccount[]
  | WsCommandAlias[]
  | WsFeedGroup[]
  | WsFeedGroupMembership[]
  | WsEvent[];

export type BootstrapEventListType = {
  bootstrapFunctionName: string;
  bootstrapFunctionCallResponseName: string;
  createManyFunctionName: (values: CreateManyPayloads) => Promise<unknown>;
  feed?: boolean;
  wait?: boolean;
  pageSize?: number;
  mine?: boolean;
};

const bootstrappedApiCalls: BootstrapEventListType[] = [
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedPermissions",
    createManyFunctionName: createManyWsPermission,
    bootstrapFunctionCallResponseName: "permissions",
    mine: true,
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeeds",
    createManyFunctionName: createManyWsFeed,
    bootstrapFunctionCallResponseName: "feeds",
    feed: true,
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedItemsPaginated",
    createManyFunctionName: createManyWsItem,
    bootstrapFunctionCallResponseName: "items",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceMemberships",
    createManyFunctionName: createManyWorkspaceMembership,
    bootstrapFunctionCallResponseName: "workspaceMemberships",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceTemplates",
    createManyFunctionName: createManyWsTemplate,
    bootstrapFunctionCallResponseName: "templates",
    pageSize: 100,
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceAccounts",
    createManyFunctionName: createManyWsAccount,
    bootstrapFunctionCallResponseName: "accounts",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedGroups",
    createManyFunctionName: createManyWsFeedGroup,
    bootstrapFunctionCallResponseName: "feedGroups",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedGroupMemberships",
    createManyFunctionName: createManyWsFeedGroupMembership,
    bootstrapFunctionCallResponseName: "feedGroupMemberships",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceCommandAliases",
    createManyFunctionName: createManyWsCommandAliases,
    bootstrapFunctionCallResponseName: "commandAliases",
  },
  {
    bootstrapFunctionName: "latestUnreadEventPerFeed",
    createManyFunctionName: createManyWsEvent,
    bootstrapFunctionCallResponseName: "events",
  },
  {
    bootstrapFunctionName: "latestUnreadEventPerFeedForOrganizers",
    createManyFunctionName: createManyWsEvent,
    bootstrapFunctionCallResponseName: "events",
  },
];

export default function useBootstrap() {
  const { client } = useContext(AppContext);
  const { trackAction, setAttribute, finishAction } =
    useContext(TelemetryContext);
  const params = useParams();
  const [invalidWorkspaceError, setInvalidWorkspaceError] =
    useState<boolean>(false);
  const [bootstrapLoading, setBootstrapLoading] = useState<boolean>(false);
  const [allComplete, setAllComplete] = useState<boolean>(false);
  const [localAllComplete, setLocalAllComplete] = useState<boolean>(false);
  const [feedIds, setFeedIds] = useState(new Map<string, boolean>());
  const [activeWorkspaceId, setActiveWorkspaceId] = useState<string | null>(
    null,
  );

  const { myAccount } = useContext(MyAccountContext);

  const [startTime] = useState(() => Date.now());

  let errorCount = 0;

  const recursiveBootstrapCall = async ({
    workspaceId,
    client,
    bootstrapEvent,
    page,
    pageSize = 50000,
    accountId,
    mine,
  }: {
    workspaceId: string;
    client: Client;
    bootstrapEvent: BootstrapEventListType;
    page: number;
    pageSize: number;
    accountId: string;
    mine?: boolean;
  }) => {
    const networkSpan = trackAction(
      actions.bootstrapNetwork(bootstrapEvent.bootstrapFunctionName, page),
    );
    networkSpan.setAttribute(
      "bootstrap.function",
      bootstrapEvent.bootstrapFunctionName,
    );
    networkSpan.setAttribute("bootstrap.page", page);
    networkSpan.setAttribute("bootstrap.pageSize", pageSize);

    try {
      const additionalResponse: BootstrapResponses = await client[
        bootstrapEvent.bootstrapFunctionName
      ]({
        workspaceId,
        page,
        pageSize,
        mine,
      });
      const responseData =
        additionalResponse[bootstrapEvent.bootstrapFunctionCallResponseName];

      if (
        bootstrapEvent.bootstrapFunctionName ===
        "bootstrapWorkspaceFeedPermissions"
      ) {
        console.log("bootstrapWorkspaceFeedPermissions", responseData, {
          workspaceId,
          page,
          pageSize,
          mine,
        });
      }

      networkSpan.setAttribute("bootstrap.responseSize", responseData.length);
      finishAction(
        actions.bootstrapNetwork(bootstrapEvent.bootstrapFunctionName, page),
      );

      if (bootstrapEvent.feed) {
        const feeds: WsFeed[] = responseData as WsFeed[];
        for (const feed of feeds) {
          feedIds.set(feed.id, true);
        }
      }

      if (responseData?.length > 0) {
        const subSpan = trackAction(
          actions.bootstrapDbWrite(bootstrapEvent.bootstrapFunctionName, page),
        );

        subSpan.setAttribute(
          "bootstrap.function",
          bootstrapEvent.bootstrapFunctionName,
        );
        subSpan.setAttribute("bootstrap.page", page);
        subSpan.setAttribute("bootstrap.pageSize", pageSize);
        subSpan.setAttribute("bootstrap.responseSize", responseData.length);

        if (waitForWritesBeforeFetchingNextPage) {
          await bootstrapEvent.createManyFunctionName(responseData);
        } else {
          bootstrapEvent.createManyFunctionName(responseData);
        }
        finishAction(
          actions.bootstrapDbWrite(bootstrapEvent.bootstrapFunctionName, page),
        );
      } else {
        logger([
          "No data found for bootstrap event",
          bootstrapEvent.bootstrapFunctionName,
          bootstrapEvent.bootstrapFunctionCallResponseName,
          additionalResponse,
        ]);
      }

      if (responseData?.length >= pageSize) {
        return await recursiveBootstrapCall({
          workspaceId,
          client,
          bootstrapEvent,
          page: page + 1,
          pageSize,
          accountId,
        });
      }
    } catch (e) {
      errorCount += 1;
      if (errorCount >= bootstrappedApiCalls?.length) {
        throw new Error("Invalid Workspace");
      }
    }
  };

  const bootstrapPaginatedApplication = async ({
    workspaceId,
    forceBootstrap,
  }: {
    workspaceId?: string;
    forceBootstrap?: boolean;
  }) => {
    if (bootstrapLoading && !forceBootstrap) {
      return;
    }
    setAttribute("accountId", myAccount?.id);
    setAttribute("workspaceId", workspaceId);
    setAttribute(
      "bootstrap.awaited",
      waitForWritesBeforeFetchingNextPage ? "true" : "false",
    );
    trackAction(actions.bootstrapFull());
    trackAction(actions.bootstrapLoad());

    const localActiveWorkspaceId = workspaceId;
    setActiveWorkspaceId(() => localActiveWorkspaceId);
    setBootstrapLoading(() => true);
    const bootstrapPromises = [];

    if (bootstrappedApiCalls?.length > 0) {
      for (const bootstrapEvent of bootstrappedApiCalls) {
        bootstrapPromises.push(
          recursiveBootstrapCall({
            workspaceId,
            client,
            bootstrapEvent,
            page: 0,
            pageSize: bootstrapEvent.pageSize,
            accountId: myAccount?.id,
            mine: bootstrapEvent.mine,
          }),
        );
      }
    }

    await Promise.all(bootstrapPromises)
      .then(() => setInvalidWorkspaceError(false))
      .catch((e) => {
        if (e?.message === "Invalid Workspace") {
          setInvalidWorkspaceError(true);
        }
      });

    setLocalAllComplete(() => true);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const updateLocalFeed = async () => {
      trackAction(actions.bootstrapAfterHooksInitialUnreadItems());
      setAttribute("accountId", myAccount?.id);

      const myCurrentWorkspaceMembership = await db.query.workspaceMembership
        .findFirst({
          where: and(
            eq(workspaceMembership.workspaceId, activeWorkspaceId),
            eq(workspaceMembership.accountId, myAccount?.id),
            eq(workspaceMembership.status, "active"),
          ),
        })
        .execute();

      const feedKeys = Array.from(feedIds.keys());

      await initializeIsAliasFeed();
      await initializeFromAlias();
      await initializeFeedLatestActivity();
      await initializeUnreadsForAllFeeds({
        membership: myCurrentWorkspaceMembership,
        alsoSetAsRead: false,
      });

      const promises = [];
      for (const id of feedKeys) {
        // if you are on a feed, load that data in the bootstrap
        if (params?.feedId && params?.workspaceId && id === params?.feedId) {
          promises.push(
            fetchFeedItemsAndDownloadEvents(client, id, activeWorkspaceId),
          );
        }
        feedIds.delete(id);
      }
      await Promise.all(promises);
      // This can get done in parallel
      finishAction(actions.bootstrapAfterHooksInitialUnreadItems());
    };

    if (feedIds.size > 0 && localAllComplete && myAccount) {
      updateLocalFeed()
        .then(() => {
          finishAction(actions.bootstrapLoad());
          logger(["DONE: bootstrap complete", Date.now() - startTime]);
          setAllComplete(true);
        })
        .catch((e) => {
          finishAction(actions.bootstrapLoad(), { error: e.message });
          finishAction(actions.bootstrapFull(), { error: e.message });
          logger(["Error in updateLocalFeed", e], true);
        });
    } else if (feedIds?.size === 0 && localAllComplete && myAccount) {
      setAllComplete(true);
    }
  }, [feedIds, params, client, localAllComplete, myAccount]);

  return {
    invalidWorkspaceError,
    allComplete,
    bootstrapPaginatedApplication,
    activeWorkspaceId,
  };
}
