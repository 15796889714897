import { db } from "@/db/db";
import { useDrizzleQuery } from "@/db/drizzleUtils";
import { audioEncoding, transcription } from "@/db/schema";
import { Item } from "@/db/types";
import { DataContext } from "@/models/DataProvider";
import { AllPreferredLanguage } from "@/models/languages";
import { targetCodecForBrowser } from "@/utils/audio";
import { selectAudio } from "@/utils/contentSelection";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { and, asc, eq } from "drizzle-orm";
import React from "react";
import "regenerator-runtime/runtime";

export default function ItemInfoTable({ item }: { item: Item }) {
  const { preferredLanguage } = React.useContext(DataContext);

  const { rows: audioContents } = useDrizzleQuery(
    db.query.audioEncoding.findMany({
      where: eq(audioEncoding.contentId, item.contentId),
      orderBy: asc(audioEncoding.language),
    }),
  );
  const { rows: transcriptions } = useDrizzleQuery(
    db.query.transcription.findMany({
      where: and(
        eq(transcription.contentId, item.contentId),
        eq(transcription.transcriptionType, "full"),
      ),
    }),
  );

  const inputLanguage = transcriptions?.filter((t) => !t.translatedFrom)[0]
    ?.language as AllPreferredLanguage;

  const targetCodec = targetCodecForBrowser();
  const suitableAudioContent = selectAudio(
    preferredLanguage,
    audioContents?.filter((a) => a.codec === targetCodec),
    inputLanguage,
  )[0];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Item ID</TableCell>
            <TableCell>Content ID</TableCell>
            <TableCell>Account ID</TableCell>
            <TableCell>Loaded Content</TableCell>
            <TableCell>Is From Alias Member</TableCell>
            <TableCell>Is Silent</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Deleted At</TableCell>
            <TableCell>Selected Audio Language</TableCell>
            <TableCell>Selected Audio Generated Service</TableCell>
            <TableCell>Selected Audio Generated Voice</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.contentId}</TableCell>
            <TableCell>{item.accountId}</TableCell>
            <TableCell>{item?.loadedContent?.toString()}</TableCell>
            <TableCell>{item?.isFromAliasMember?.toString()}</TableCell>
            <TableCell>{item?.isSilent?.toString()}</TableCell>
            <TableCell>{item?.status}</TableCell>
            <TableCell>{item?.deletedAt}</TableCell>
            <TableCell>
              {suitableAudioContent?.language === null
                ? `original (${inputLanguage})`
                : suitableAudioContent?.language}
            </TableCell>
            <TableCell>{suitableAudioContent?.generatedService}</TableCell>
            <TableCell>{suitableAudioContent?.generatedVoice}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
