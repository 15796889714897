import UseTimeouts from "@/hooks/useTimeouts";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useEffect, useRef } from "react";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { DataContext } from "@/models/DataProvider";

/**
 * This hook is used to check the hands-free status of users
 * It uses a visible:ref as a value to see if the app has been mounted and data loaded
 * @param visible - boolean to check if the component is visible
 */
export default function UseHandsFreeStatus(visible: boolean) {
  const { fetchWorkspaceHandsFreeStatus } = useContext(DataContext);
  const { handsFreeStatusIndicator } = useFlags();
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const handsFreeAllowed = handsFreeEnabledForWorkspace(currentWorkspaceId);
  const firstBootCheck = useRef<boolean>(false);
  const handsFreeRefresh = UseTimeouts(30);

  // check on first mount and then not again
  useEffect(() => {
    if (!firstBootCheck.current && visible) {
      firstBootCheck.current = true;
      fetchWorkspaceHandsFreeStatus();
    }
  }, [visible]);

  // only check when a new handsFreeRefresh is requested -> 65 seconds
  useEffect(() => {
    if (
      handsFreeAllowed &&
      handsFreeStatusIndicator &&
      handsFreeRefresh?.refresh === true
    ) {
      fetchWorkspaceHandsFreeStatus();
    }
  }, [handsFreeAllowed, handsFreeStatusIndicator, handsFreeRefresh?.refresh]);
}
