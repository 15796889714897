import UseContextMenu from "@/components/ContextMenus/UseContextMenu";
import LinkContextMenu from "@/components/WorkspaceChannelList/LinkContextMenu";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import LockIcon from "@mui/icons-material/Lock";
import { Box } from "@mui/material";
import { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChannelList } from "@/models/commonQueries";
import { actions, TelemetryContext } from "@/models/TelemetryProvider";

interface LinkProps {
  channel: ChannelList;
  active: boolean;
  readOnlyMode: boolean;
  workspaceMembershipId: string;
  limitedMember: boolean;
}

export default function WorkspaceChannelLinkItem({
  channel,
  active,
  readOnlyMode,
  workspaceMembershipId,
  limitedMember,
}: LinkProps) {
  const { handleContextMenu, handleClose, contextMenuItem, contextMenu } =
    UseContextMenu();
  const { trackAction } = useContext(TelemetryContext);
  const { joinPublicChannel } = useContext(DataContext);
  const { workspaceId, feedId } = useParams();
  const navigate = useNavigate();
  const channelUrl = `/workspaces/${channel.workspaceId}/feeds/${channel.id}`;
  const [disabled, setDisabled] = useState<boolean>(false);

  const joinChannel = async (id: string, redirect: boolean) => {
    setDisabled(() => true);
    await joinPublicChannel(workspaceId, id);
    handleClose();
    setDisabled(() => false);
    if (feedId !== id && redirect) {
      navigate(channelUrl);
    }
  };

  const debug = false;

  return (
    <Link
      data-testid={Locator.workspaceNav.channels.list.testId(channel.id)}
      aria-label={Locator.workspaceNav.channels.list.item}
      to={`${disabled ? "#" : channelUrl}`}
      onContextMenu={!limitedMember && handleContextMenu}
      className={`
        workspace-channel-item
        ${active ? "active" : ""}
        ${channel?.joined ? "joined" : "not-joined"}
        ${disabled ? "disabled" : ""}
      `}
      onClick={async (e) => {
        if (contextMenu) {
          e.preventDefault();
          return;
        }
        if (!channel?.joined) {
          e.preventDefault();
          await joinChannel(channel.id, true);
        }
        trackAction(actions.feedNavigate(channel.id));
      }}
    >
      <Box
        className={`
          workspace-channel-item-container
          ${channel?.groupId ? "has-group" : ""}
        `}
      >
        <Box className="workspace-channel-item-container-left ">
          {channel?.isPrivate && !channel?.aliasChannel && (
            <Box className="workspace-channel-item-lock">
              <LockIcon sx={{ fontSize: "1.125rem" }} />
            </Box>
          )}

          <Box
            sx={{ flexGrow: 1 }}
            className="truncate"
            aria-label={channel.title}
          >
            {channel.title}
          </Box>
        </Box>
        {channel?.groupId && (
          <Box
            className="workspace-channel-item-container-right truncate"
            aria-label={channel.groupName}
          >
            <Box className="group-name">{channel.groupName}</Box>
          </Box>
        )}
        <Box className="unread-bubble-container">
          <Box
            className={`
              unread-bubble
              ${channel.unread && channel.joined ? "unread" : ""}
              ${channel.unread && !channel.joined ? "unread-not-joined" : ""}
            `}
          />
        </Box>
      </Box>
      {debug && (
        <Box>
          {channel?.aliasChannel && (
            <Box
              sx={{
                background: "#591595",
                display: "inline-block",
                fontSize: "12px",
                padding: "5px",
              }}
            >
              Alias Channel
            </Box>
          )}
          <Box
            sx={{
              fontSize: "11px",
              background: "rgba(0,0,0, .3)",
              marginRight: "1rem",
              padding: "0.5rem",
              border:
                debug && channel?.aliasChannel
                  ? "solid 2px #591595"
                  : "solid 1px #444",
            }}
          >
            <Box>Channel Item Debug</Box>
            <Box>id: {channel?.id}</Box>
            <Box>
              unified: {new Date(channel?.unifiedTimestamp).toLocaleString()}
            </Box>
            <Box>
              Activity: {new Date(channel?.driverActivity).toLocaleString()}
            </Box>
            <Box>
              Activity: {new Date(channel?.latestActivity).toLocaleString()}
            </Box>
            <Box>
              FeedActivity:{" "}
              {new Date(channel.feedLatestActivity).toLocaleString()}
            </Box>
            <Box>
              FeedALIASActivity:{" "}
              {new Date(channel.feedLatestAliasActivity).toLocaleString()}
            </Box>

            <Box>unread: {channel?.unread?.toString()}</Box>
          </Box>
        </Box>
      )}

      {contextMenuItem(
        <LinkContextMenu
          readOnlyMode={readOnlyMode}
          channel={channel}
          workspaceMembershipId={workspaceMembershipId}
          channelUrl={channelUrl}
          handleClose={handleClose}
        />,
      )}
    </Link>
  );
}
