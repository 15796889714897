import Loading from "@/components/Loading/Index";
import { MODAL_DEFAULT_Z_INDEX } from "@/elements/ModalForm";
import { KeyboardArrowDown } from "@mui/icons-material";
// used for type definitions of lab components
import type {} from "@mui/lab/themeAugmentation";
import { Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import type { ThemeOptions } from "@mui/material/styles/createTheme";

//Extend attributes of attributes
//Definition start
type DataAttributeKey = `data-${string}`;
declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    [dataAttribute: DataAttributeKey]: unknown;
  }
}
//Definition end

// declare custom theme variables
// define the new variable to add it to the theme variable
declare module "@mui/material/styles" {
  interface Palette {
    brand: {
      primary: {
        light: string;
        main: string;
        dark: string;
        darkHighlight: string;
        darker: string;
      };
      secondary: Palette["secondary"];
      info: Palette["info"];
      other: {
        teal: Palette["primary"];
        black: Palette["primary"];
        gray: Palette["primary"];
        green: Palette["primary"];
      };
      mute: {
        activeHover: string;
        active: string;
      };
    };
  }

  interface PaletteOptions {
    brand?: {
      primary?: {
        light: string;
        main: string;
        dark: string;
        darkHighlight?: string;
        darker?: string;
      };
      secondary?: PaletteOptions["secondary"];
      other?: {
        teal?: PaletteOptions["primary"];
        black?: PaletteOptions["primary"];
        gray?: PaletteOptions["primary"];
        green?: PaletteOptions["primary"];
      };
      info?: PaletteOptions["info"];
      mute?: {
        activeHover: string;
        active: string;
      };
    };
  }

  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      light: "#fafafa",
      main: "#ffffff",
      dark: "#040404",
    },
    secondary: {
      light: "#737579",
      main: "#404449",
      dark: "#26292C",
    },
    neutral: {
      main: "#C0C0C2",
      dark: "#1B1D1F",
    },
    info: {
      light: "#D79BFF",
      main: "#8803FF",
      dark: "#8803FF",
    },
    success: {
      light: "#2C9B4D",
      main: "#07892D",
      dark: "#046e23",
    },
    error: {
      light: "#E63B3B",
      main: "#E21818",
      dark: "#CA1D1D",
      contrastText: "#FC2424",
    },
    warning: {
      light: "#ffd014",
      main: "#FFBD14",
      dark: "#CE9500",
    },
    brand: {
      primary: {
        light: "#A929FE",
        main: "#9F11FE",
        dark: "#591595",
        darkHighlight: "#7F0ECB4D",
        darker: "#400766",
      },
      secondary: {
        light: "#FF723E",
        main: "#FF6229",
        dark: "#F54100",
      },
      other: {
        teal: { light: "#007A7A", main: "#004949", dark: "#003131" },
        black: { main: "#202225" },
        gray: { main: "#939496", dark: "#5B5B5B" },
        green: { main: "#3FFF77", dark: "#07892D" },
      },
      info: {
        light: "#07689F",
        main: "#264ACC",
        dark: "#0728A1",
      },
      mute: {
        activeHover: "#FFCBB9",
        active: "#FFE0D4",
      },
    },
  },
} as ThemeOptions;
const themeBase = createTheme(themeOptions);

const themeComponents = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "html, body": {
          backgroundColor: themeBase.palette.primary.dark,
        },
        body: {
          // moved to hardcoded value to avoid build issues with mui
          background:
            "linear-gradient(180deg, rgba(6, 6, 6, 0.9) 0%, #23262B 18.23%, #111416 100%)",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "12px",
        },
        message: {
          padding: "4px 0",
          fontWeight: 700,
        },
        filledSuccess: {
          background: themeBase.palette.success.main,
        },
        action: {
          ".MuiIconButton-root": {
            background: "transparent",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minWidth: 48,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: themeBase.palette.primary.main,
          fontSize: "1rem",
          fontWeight: 700,
          border: "2px solid transparent",
          "&:disabled": {
            cursor: "not-allowed",
            color: themeBase.palette.neutral.main,
            background: themeBase.palette.secondary.light,
          },
        },
        containedPrimary: {
          padding: "12px 20px",
          height: "44px",
          borderRadius: "60px",
          background: themeBase.palette.brand.primary.main,
          "&:hover:not([disabled]), &:focus:not([disabled])": {
            background: themeBase.palette.brand.primary.light,
            boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
          },
          "&:focus:not([disabled])": {
            border: `2px solid ${themeBase.palette.info.light}`,
          },
        },
        containedSecondary: {
          padding: "12px 20px",
          height: "44px",
          borderRadius: "60px",
          background: themeBase.palette.secondary.main,
          "&:disabled": {
            boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
          },
          "&:hover:not([disabled]), &:focus:not([disabled])": {
            background: themeBase.palette.secondary.light,
            boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
          },
          "&:focus:not([disabled])": {
            border: `2px solid ${themeBase.palette.neutral.main}`,
          },
        },
        containedError: {
          padding: "12px 20px",
          height: "44px",
          borderRadius: "60px",
          background: themeBase.palette.error.main,
          "&:disabled": {
            boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
          },
          "&:hover:not([disabled]), &:focus:not([disabled])": {
            background: themeBase.palette.error.light,
            boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
          },
          "&:focus:not([disabled])": {
            border: `2px solid ${themeBase.palette.error.dark}`,
          },
        },
        outlinedPrimary: {
          padding: "12px 20px",
          height: "44px",
          borderRadius: "60px",
          background: themeBase.palette.secondary.dark,
          border: `2px solid ${themeBase.palette.neutral.main}`,
          "&:disabled": {
            boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
          },
          "&:hover:not([disabled])": {
            borderWidth: "2px",
          },
        },
      },
      variants: [
        {
          props: { variant: "link" },
          style: {
            color: themeBase.palette.primary.main,
            fontWeight: 500,
            lineHeight: 1,
            padding: 0,
            border: "none",
            textTransform: "none",
            textDecoration: "underline",
            "&:hover:not([disabled]), &:focus:not([disabled])": {
              textDecoration: "none",
              backgroundColor: "transparent",
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: themeBase.palette.brand.primary.main,
          },
        },
      },
    },
    // @ts-ignore
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: "100%",
          maxHeight: 380,
          p: 4,
          border: `1px solid ${themeBase.palette.neutral.main}`,
          borderRadius: "8px",
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          margin: "0 auto",
          fontSize: "1rem",
          color: themeBase.palette.primary.main,
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        showColumnVerticalBorder: false,
        showCellVerticalBorder: false,
        rowSelection: false,
        disableColumnMenu: true,
        disableRowSelectionOnClick: true,
        columnHeaderHeight: 70,
        rowHeight: 64,
        sortingOrder: ["asc", "desc"],
        ignoreDiacritics: true,
        slots: {
          quickFilterClearIcon: () => (
            <Typography sx={{ fontWeight: 500, lineHeight: 1 }}>
              Clear
            </Typography>
          ),
        },
      },
      styleOverrides: {
        root: {
          border: "none",
          fontSize: "1rem",
          ".MuiIconButton-root": {
            background: "transparent",
          },
          ".MuiDataGrid-row--lastVisible": {
            marginBottom: 120,
          },
        },
        cell: {
          "&:focus, &:focus-within": {
            outline: "none",
          },
        },
        withBorderColor: {
          border: "none",
        },
        columnHeaders: {
          borderBottom: `1px solid ${themeBase.palette.primary.main} !important`,
        },
        columnHeader: {
          "&:focus, &:focus-within": {
            outline: "none",
          },
        },
        columnHeaderTitleContainer: {
          gap: "8px",
        },
        columnSeparator: { display: "none" },
        toolbarQuickFilter: {
          width: "100%",
          borderRadius: 8,
          border: `1.5px solid ${themeBase.palette.secondary.light}`,
          position: "relative",
          background: themeBase.palette.secondary.dark,
          padding: 0,
          "& .MuiInputBase-root.MuiInput-root": {
            "::before, ::after": {
              content: "none",
            },
            fontSize: 14,
            padding: "4px 8px",
            transition: themeBase.transitions.create([
              "border-color",
              "background-color",
              "box-shadow",
            ]),
            boxSizing: "border-box",
            minHeight: 41,
          },
          "&:focus-within": {
            borderColor: themeBase.palette.primary.main,
          },
          input: {
            marginLeft: "8px",
          },
        },
        virtualScroller: {
          overflow: "hidden",
          scrollbarGutter: "stable",
          "&:hover, &:active, &:focus": {
            overflow: "auto",
          },
        },
        footerContainer: {
          minHeight: 88,
          height: "auto",
          position: "absolute",
          bottom: "16px",
          width: "100%",
          maxWidth: "calc(100% - 20px)",
          alignSelf: "center",
          [themeBase.breakpoints.up("sm")]: {
            maxWidth: 1280,
          },
        },
      },
    },
    MuiDigitalClock: {
      styleOverrides: {
        item: {
          "&.Mui-selected": {
            backgroundColor: themeBase.palette.brand.primary.main,
            color: themeBase.palette.primary.main,
            "&:hover": {
              backgroundColor: themeBase.palette.brand.primary.light,
              boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderLeft: "none",
          borderRight: "none",
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: themeBase.palette.secondary.dark,
          color: themeBase.palette.neutral.light,
          border: "1.5px solid transparent",
          "&:hover:not([disabled]), &:focus:not([disabled])": {
            color: themeBase.palette.primary.main,
            background: themeBase.palette.secondary.light,
          },
          "&:focus:not([disabled])": {
            border: `1.5px solid ${themeBase.palette.secondary.light}`,
          },
          "&:disabled": {
            background: themeBase.palette.secondary.light,
            color: themeBase.palette.neutral,
          },
          marginLeft: 0,
        },
        colorPrimary: {
          color: themeBase.palette.primary.main,
          background: "transparent",
          "&:hover, &:focus": {
            color: themeBase.palette.primary.main,
            background: "transparent",
            border: `1.5px solid ${themeBase.palette.primary.main}`,
          },
        },
        colorSecondary: {
          color: themeBase.palette.neutral.main,
          background: "transparent",
          "&:hover, &:focus": {
            background: themeBase.palette.secondary.dark,
            borderColor: themeBase.palette.secondary.light,
          },
        },
        colorSuccess: {
          color: themeBase.palette.primary.main,
          background: themeBase.palette.success.main,
          "&:hover:not([disabled]), &:focus:not([disabled])": {
            background: themeBase.palette.success.light,
          },
          "&:focus:not([disabled])": {
            background: themeBase.palette.secondary.light,
            border: `1.5px solid ${themeBase.palette.secondary.light}`,
          },
        },
        colorError: {
          color: themeBase.palette.primary.main,
          background: themeBase.palette.error.main,
          "&:hover:not([disabled])": {
            background: themeBase.palette.error.light,
          },
          "&:focus:not([disabled])": {
            background: themeBase.palette.secondary.light,
            border: `1.5px solid ${themeBase.palette.primary.main}`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          color: themeBase.palette.primary.main,
          transform: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textUnderlineOffset: "4px",
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingIndicator: <Loading variant="disabled" size="tiny" />,
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: themeBase.palette.secondary.dark,
          border: `1px solid ${themeBase.palette.secondary.light}`,
          borderRadius: "8px",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          margin: "0 auto",
          "&.Mui-selected": {
            color: themeBase.palette.primary.main,
            background: themeBase.palette.brand.primary.light,
            border: `1px solid ${themeBase.palette.brand.primary.light}`,
            fontWeight: 400,
            "&:hover:not([disabled]), &:focus:not([disabled])": {
              background: themeBase.palette.brand.primary.light,
              boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
            },
            "&:focus:not([disabled])": {
              border: `2px solid ${themeBase.palette.info.light}`,
            },
          },
        },
        today: {
          background: "transparent",
          border: "none",
          "&:not(.Mui-selected)": {
            border: "none",
            background: "transparent",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 2300,
          bottom: "100% !important",
          marginTop: "8px !important",
        },
        paper: {
          border: `1.5px solid ${themeBase.palette.secondary.light}`,
          boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
          background: themeBase.palette.secondary.dark,
          borderRadius: "0.5rem",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        root: {
          "&::after": {
            content: "none",
          },
        },
        item: {
          border: "2px solid transparent",
          "&.Mui-selected": {
            backgroundColor: themeBase.palette.brand.primary.main,
            color: themeBase.palette.primary.main,
            "&:hover:not([disabled]), &:focus:not([disabled])": {
              background: themeBase.palette.brand.primary.light,
              boxShadow: "0 4px 8px 0 rgba(26, 26, 26, 0.2)",
            },
            "&:focus:not([disabled])": {
              border: `2px solid ${themeBase.palette.info.light}`,
            },
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: themeBase.palette.primary.main,
          padding: "12px 8px",
          fontWeight: 700,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: themeBase.palette.brand.primary.light,
          bottom: "8px",
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        labelRowsPerPage: false,
        labelDisplayedRows: () => "per page",
        SelectProps: {
          autoWidth: true,
          IconComponent: KeyboardArrowDown,
          MenuProps: {
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            PaperProps: {
              elevation: 0,
              sx: {
                width: 60,
                border: 0,
                background: themeBase.palette.brand.primary.main,
                boxShadow: "none",
                borderRadius: "4px",
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                marginTop: "4px",
                marginLeft: "-0.5px",
              },
            },
            MenuListProps: {
              sx: {
                paddingTop: "8px",
                paddingBottom: "2px",
              },
            },
          },
        },
      },
      styleOverrides: {
        root: {
          width: "100%",
          ".MuiPaginationItem-icon": {
            fontSize: "2.25rem",
          },
          ".MuiPagination-ul": {
            ".MuiButtonBase-root.MuiPaginationItem-root": {
              fontWeight: 600,
              "&.Mui-selected": {
                color: themeBase.palette.primary.main,
                background: themeBase.palette.brand.primary.main,
              },
              // disable Ripple Effect
              "> .MuiTouchRipple-root": {
                display: "none",
              },
            },
          },
        },
        toolbar: {
          borderRadius: "8px",
          padding: "24px 12px",
          height: 88,
          background: themeBase.palette.neutral.dark,
          justifyContent: "space-between",
          width: "100%",
        },
        actions: {
          marginLeft: "auto",
        },
        displayedRows: {
          fontSize: "1rem",
          fontWeight: 600,
        },
        input: {
          display: "flex !important",
          marginLeft: 0,
          marginRight: "12px",
          lineHeight: 1,
          background: themeBase.palette.brand.primary.main,
          fontWeight: 700,
          width: 60,
          height: 32,
          justifyContent: "center",
          borderRadius: "4px",
          alignItems: "center",
          "> svg": {
            right: "2px",
          },
        },
        select: {
          textAlign: "left",
          textAlignLast: "left",
          padding: "8px",
          fontSize: "1rem",
          "&.MuiInputBase-input": {
            minHeight: 0,
          },
          '&[aria-expanded="true"]': {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          },
        },
        spacer: {
          display: "none",
        },
        menuItem: {
          fontWeight: 600,
          lineHeight: 1,
          padding: "4px 8px",
          "&.Mui-selected": {
            display: "none",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          borderRadius: "1000px",
          background: themeBase.palette.brand.other.black.main,
          boxShadow: "0px 2px 8px 0px rgba(4, 7, 5, 0.25)",
          height: 64,
          maxWidth: 1300,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: MODAL_DEFAULT_Z_INDEX,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "label + &": {
            marginTop: themeBase.spacing(3.5),
          },
          "> .MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderRadius: 0,
            borderColor: "transparent",
            "&:hover": {
              borderColor: "transparent",
            },
          },
          "& .MuiInputBase-input": {
            borderRadius: 8,
            border: `1.5px solid ${themeBase.palette.secondary.light}`,
            boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
            position: "relative",
            background: themeBase.palette.secondary.dark,
            fontSize: 14,
            width: "100%",
            padding: 8,
            transition: themeBase.transitions.create([
              "border-color",
              "background-color",
              "box-shadow",
            ]),
            "&:focus": {
              borderColor: themeBase.palette.primary.main,
            },
            boxSizing: "border-box",
            minHeight: 44,
          },
          "&.Mui-error, &.Mui-error > .MuiInputBase-input,  &.Mui-error + p": {
            color: themeBase.palette.error.main,
            borderColor: themeBase.palette.error.main,
          },
          "+ p": {
            fontSize: 12,
            fontWeight: 500,
            color: themeBase.palette.primary.main,
          },
          "& .MuiOutlinedInput-input:focus": {
            borderRadius: 8,
          },
          "::before, ::after": {
            content: "none",
          },
        },
        filled: {
          display: "flex",
          fontSize: "1rem",
          lineHeight: 1,
          background: themeBase.palette.brand.primary.main,
          border: 0,
          fontWeight: 700,
          width: 60,
          height: 32,
          justifyContent: "center",
          borderRadius: "4px",
          alignItems: "center",
          ".MuiInputBase-input": {
            borderRadius: 4,
            border: 0,
            position: "relative",
            background: themeBase.palette.secondary.dark,
            fontSize: "1rem",
            width: "100%",
            padding: "6px 8px",
            boxSizing: "border-box",
            minHeight: 0,
            height: 32,
            "> svg": {
              right: "2px",
            },
          },
          "& .MuiSvgIcon-root-MuiSelect-icon .MuiSelect-icon": {
            right: "2px",
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
        focusVisibleClassName: ".Mui-focusVisible",
      },
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
        },
        switchBase: {
          padding: 0,
          margin: 2,
          transitionDuration: "300ms",
          "&.Mui-checked": {
            transform: "translateX(16px)",
            color: themeBase.palette.primary.main,
            "& + .MuiSwitch-track": {
              borderColor: themeBase.palette.brand.primary.light,
              backgroundColor: themeBase.palette.brand.primary.main,
              opacity: 1,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            outline: `solid 2px ${themeBase.palette.primary.light}`,
            outlineOffset: "2px",
            boxShadow: `0 0 0 4px ${themeBase.palette.info.main}`,
          },
        },
        thumb: {
          boxSizing: "border-box",
          width: 22,
          height: 22,
        },
        track: {
          borderRadius: 26 / 2,
          border: `1px solid ${themeBase.palette.secondary.light}`,
          backgroundColor: themeBase.palette.secondary.dark,
          opacity: 1,
          transition: themeBase.transitions.create(["background-color"], {
            duration: 500,
          }),
        },
        sizeSmall: {
          width: 36,
          height: 18,
          ".MuiSwitch-switchBase": {
            margin: "-1px 0",
          },
          ".MuiSwitch-thumb": {
            width: 12,
            height: 12,
          },
        },
      },
    },
  },
});

const theme = createTheme({
  ...themeBase,
  components: themeComponents.components,
});

export default theme;
