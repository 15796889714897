import {
  useLiveIncrementalQuery,
  useLiveQuery,
} from "@electric-sql/pglite-react";
import { SQL } from "drizzle-orm";
import type { PgSelectBase } from "drizzle-orm/pg-core";
import type { PgRelationalQuery } from "drizzle-orm/pg-core/query-builders/query";
import { db } from "./db";
import type { LiveQuery, LiveQueryResults } from "@electric-sql/pglite/live";
import { useEffect, useRef, useState } from "react";

const debugQuery = false;

function printDebugQuery(name: string, query) {
  if (debugQuery) {
    console.log(query.toSQL());
  }
}

export function useDrizzleIncSelect<
  TTableName,
  TSelection,
  TSelectMode,
  TNullabilityMap,
  TDynamic,
  TExcludedMethods,
  TResult,
  TSelectedFields,
  T,
>(
  query: Omit<
    PgSelectBase<
      TTableName,
      TSelection,
      TSelectMode,
      TNullabilityMap,
      TDynamic,
      TExcludedMethods,
      TResult,
      TSelectedFields
    >,
    T
  >,
) {
  printDebugQuery("select query", query);
  const results = useLiveIncrementalQuery(
    query.toSQL().sql,
    query.toSQL().params,
    "id",
  );
  const rows = (results?.rows || []) as unknown as Awaited<typeof query>;
  return { rows };
}

export function useDrizzleSelect<
  TTableName,
  TSelection,
  TSelectMode,
  TNullabilityMap,
  TDynamic,
  TExcludedMethods,
  TResult,
  TSelectedFields,
  T,
>(
  query: Omit<
    PgSelectBase<
      TTableName,
      TSelection,
      TSelectMode,
      TNullabilityMap,
      TDynamic,
      TExcludedMethods,
      TResult,
      TSelectedFields
    >,
    T
  >,
  debugName?: string,
) {
  // // printDebugQuery("select query", query);
  const q = query.toSQL();
  const qSql = q.sql;
  // const qParams = q.params?.map((p) => (p === undefined ? "none" : p));
  const qParams = q.params;
  // db?.$client?.live.query(qSql, qParams).then((r) => {
  //   if (name) {
  //     console.log(name, { r });
  //   }
  // });

  // const results = useLiveQuery(qSql, qParams);
  // if (name) {
  //   console.log(name, { results, qParams, qSql });
  // }

  const results = useLiveQueryImpl(qSql, qParams, debugName);
  const rows = (results?.rows || []) as unknown as Awaited<typeof query>;
  return { rows };
}

export function useDrizzleRawQuery<T>(query: SQL<unknown>) {
  if (debugQuery) {
    console.log("raw query", query);
  }
  return useLiveQuery(`${query}`) || [];
}

export function useDrizzleIncQuery<T>(query: PgRelationalQuery<T>) {
  printDebugQuery("inc query", query);

  const results = useLiveIncrementalQuery(
    query.toSQL().sql,
    query.toSQL().params,
    "id",
  );
  const rows = (results?.rows || []) as Awaited<T>;
  return { rows };
}

export function useDrizzleQuery<T>(
  query?: PgRelationalQuery<T>,
  debugName?: string,
) {
  printDebugQuery("query", query);
  // const results = useLiveQuery(query.toSQL().sql, query.toSQL().params);
  const results = useLiveQueryImpl(
    query.toSQL().sql,
    query.toSQL().params,
    debugName,
  );
  const rows = (results?.rows || []) as Awaited<T>;
  return { rows };
}
function paramsEqual(
  a1: unknown[] | undefined | null,
  a2: unknown[] | undefined | null,
) {
  if (!a1 && !a2) return true;
  if (a1?.length !== a2?.length) return false;
  for (let i = 0; i < a1!.length; i++) {
    if (!Object.is(a1![i], a2![i])) {
      return false;
    }
  }
  return true;
}

function useLiveQueryImpl<T = { [key: string]: unknown }>(
  query: string | LiveQuery<T> | Promise<LiveQuery<T>>,
  params: unknown[] | undefined | null,
  debugName?: string,
): Omit<LiveQueryResults<T>, "affectedRows"> | undefined {
  const pg = db.$client;
  const paramsRef = useRef(params);
  const [results, setResults] = useState<LiveQueryResults<T> | undefined>();
  let paramsChanged = false;

  let currentParams = paramsRef.current;
  if (!paramsEqual(paramsRef.current, params)) {
    paramsRef.current = params;
    currentParams = params;
    paramsChanged = true;
  }

  /* eslint-disable @eslint-react/hooks-extra/no-direct-set-state-in-use-effect */
  useEffect(() => {
    let cancelled = false;
    const cb = (results: LiveQueryResults<T>) => {
      if (cancelled) return;
      setResults(results);
    };
    if (typeof query === "string") {
      const ret = pg.live.query<T>(query, currentParams, cb);

      // if (paramsChanged) {
      //   paramsChanged = false;
      //   ret.then((r) =>
      //     setTimeout(() => {
      //       console.log("REFRESHING", r);
      //       r.refresh();
      //     }, 1000),
      //   );
      // }

      return () => {
        cancelled = true;
        ret.then(({ unsubscribe }) => unsubscribe());
      };
    } else {
      throw new Error("Should never happen");
    }
  }, [pg, debugName, query, currentParams, paramsChanged]);
  /* eslint-enable @eslint-react/hooks-extra/no-direct-set-state-in-use-effect */

  if (debugName) {
    console.log(`CUSTOM: ${debugName}`, results);
  }
  return (
    results && {
      rows: results.rows,
      fields: results.fields,
      totalCount: results.totalCount,
      offset: results.offset,
      limit: results.limit,
    }
  );
}
