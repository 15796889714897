import { live } from "@electric-sql/pglite/live";
import { drizzle } from "drizzle-orm/pglite";
import * as schema from "./schema.js";

import { PGlite } from "@electric-sql/pglite";
// import { PGliteWorker } from "@electric-sql/pglite/worker";
const client = await PGlite.create({
  // dataDir: "idb://sbv2",
  // initialMemory: 1024 * 1024 * 1024 * 2,
  relaxedDurability: true,
  extensions: { live },
});

// const client = await PGliteWorker.create(
//   new Worker(new URL("/pglite-worker.js", import.meta.url), {
//     type: "module",
//   }),
//   {
//     // dataDir: "idb://sbv2",
//     // relaxedDurability: true,
//     extensions: { live },
//   },
// );

const db = drizzle(client, { schema });

export { db, client };
