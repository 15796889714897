import { db } from "@/db/db";
import {
  account,
  accountEvent,
  audioEncoding,
  displayArtifact,
  file,
  item,
  link,
  pipelineArtifactMetadata,
  transcription,
  workspaceMembership,
  wsHandsFreeStatus,
} from "@/db/schema";
import {
  and,
  asc,
  eq,
  getTableColumns,
  isNotNull,
  isNull,
  or,
  sql,
} from "drizzle-orm";
import { emptyQuery } from "./commonQueries";
import { logger } from "@/utils/logging";

export function getItems(currentFeedId?: string) {
  const query = db.query.item.findMany({
    where: and(eq(item.feedId, currentFeedId), isNull(item.deletedAt)),
    orderBy: asc(item.createdAt),
  });
  if (!currentFeedId) {
    logger("FEED LOADING: Items Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

export function getTranscription(
  currentFeedId?: string,
  selectedLanguage?: string,
) {
  const query = db
    .select({
      ...getTableColumns(transcription),
    })
    .from(transcription)
    .innerJoin(
      item,
      and(
        eq(transcription.contentId, item.contentId),
        eq(item.feedId, currentFeedId),
        eq(transcription.language, selectedLanguage),
      ),
    );

  if (!currentFeedId || !selectedLanguage) {
    logger("FEED LOADING: Transcriptions Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

export function getAudioEncoding(currentFeedId?: string) {
  const query = db
    .select({
      ...getTableColumns(audioEncoding),
    })
    .from(audioEncoding)
    .innerJoin(
      item,
      and(
        eq(audioEncoding.contentId, item.contentId),
        eq(item.feedId, currentFeedId),
      ),
    );

  if (!currentFeedId) {
    logger("FEED LOADING: Audio Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

export function getDisplayArtifacts(currentFeedId?: string) {
  const query = db
    .select({
      ...getTableColumns(displayArtifact),
    })
    .from(displayArtifact)
    .innerJoin(
      item,
      and(
        eq(displayArtifact.contentId, item.contentId),
        eq(item.feedId, currentFeedId),
      ),
    );
  if (!currentFeedId) {
    logger("FEED LOADING: Display artifacts Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

export function getFiles(currentFeedId?: string) {
  const query = db
    .select({
      ...getTableColumns(file),
    })
    .from(file)
    .innerJoin(
      item,
      and(eq(file.contentId, item.contentId), eq(item.feedId, currentFeedId)),
    )
    .groupBy(file.id);

  if (!currentFeedId) {
    logger("FEED LOADING: Files Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

export function getLinks(currentFeedId?: string) {
  const query = db
    .select({
      ...getTableColumns(link),
    })
    .from(link)
    .innerJoin(
      item,
      and(eq(link.contentId, item.contentId), eq(item.feedId, currentFeedId)),
    );

  if (!currentFeedId) {
    logger("FEED LOADING: Links Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

export function getPams(currentFeedId?: string) {
  const query = db
    .select({
      ...getTableColumns(pipelineArtifactMetadata),
    })
    .from(pipelineArtifactMetadata)
    .innerJoin(
      item,
      and(
        eq(pipelineArtifactMetadata.contentId, item.contentId),
        eq(item.feedId, currentFeedId),
      ),
    );

  if (!currentFeedId) {
    logger("FEED LOADING: Pam Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

function getSenderAccount(currentFeedId?: string) {
  const query = db
    .selectDistinct({
      ...getTableColumns(account),
      status: wsHandsFreeStatus.status,
      lastOnline: wsHandsFreeStatus.lastOnline,
    })
    .from(account)
    .leftJoin(
      workspaceMembership,
      eq(account.id, workspaceMembership.accountId),
    )
    .leftJoin(
      item,
      and(eq(account.id, item.accountId), eq(item.feedId, currentFeedId)),
    )
    .leftJoin(
      accountEvent,
      and(
        eq(accountEvent.accountId, account.id),
        eq(accountEvent.feedId, currentFeedId),
      ),
    )
    .leftJoin(
      wsHandsFreeStatus,
      eq(wsHandsFreeStatus.id, workspaceMembership.id),
    )
    .where(or(isNotNull(accountEvent.id), isNotNull(item.id)));

  if (!currentFeedId) {
    logger("FEED LOADING: Sender Account Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

export function workspaceAccountsWithStatusSelect(workspaceId?: string) {
  const query = db
    .selectDistinct({
      ...getTableColumns(account),
      status: wsHandsFreeStatus.status,
      lastOnline: wsHandsFreeStatus.lastOnline,
      workspaceMembershipId: sql`${workspaceMembership.id} as "workspaceMembershipId"`,
      role: workspaceMembership.role,
    })
    .from(account)
    .innerJoin(
      workspaceMembership,
      and(
        eq(account.id, workspaceMembership.accountId),
        eq(workspaceMembership.workspaceId, workspaceId),
        // eq(workspaceMembership.status, "active"),
      ),
    )
    .leftJoin(
      wsHandsFreeStatus,
      eq(wsHandsFreeStatus.id, workspaceMembership.id),
    );

  if (!workspaceId) {
    logger("FEED LOADING: Workspace Account Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}

export function eventsForFeedItems(currentFeedId?: string) {
  const query = db
    .selectDistinct({
      ...getTableColumns(accountEvent),
    })
    .from(accountEvent)
    .innerJoin(
      item,
      and(eq(item.feedId, currentFeedId), eq(accountEvent.itemId, item.id)),
    );
  if (!currentFeedId) {
    logger("FEED LOADING: Events Returning empty query");
    return emptyQuery as unknown as typeof query;
  }
  return query;
}
interface artifact {
  contentId: string;
}
export function mapForArtifact<T extends artifact>(
  rows: T[],
  debugName?: string,
) {
  // logger(["Generating map for artifact", debugName]);
  const map = new Map<string, T[]>();
  for (const r of rows) {
    if (map.has(r.contentId)) {
      const t = map.get(r.contentId);
      t.push(r);
    } else {
      map.set(r.contentId, [r]);
    }
  }
  return map;
}
interface hasId {
  id: string;
}
export function mapForHasId<T extends hasId>(rows: T[], debugName?: string) {
  // logger(["Generating map for hasId", debugName]);
  const map = new Map<string, T>(rows.map((r) => [r.id, r]));
  return map;
}

interface hasItemId {
  itemId: string;
}
export function mapForHasItemId<T extends hasItemId>(rows: T[]) {
  const map = new Map<string, T[]>();
  for (const r of rows) {
    if (map.has(r.itemId)) {
      const t = map.get(r.itemId);
      t.push(r);
    } else {
      map.set(r.itemId, [r]);
    }
  }
  return map;
}

interface hasWorkspaceMembershipId {
  workspaceMembershipId: string;
}
export function mapForHasWorkspaceMembershipIdMany<
  T extends hasWorkspaceMembershipId,
>(rows: T[]) {
  const map = new Map<string, T[]>();
  for (const r of rows) {
    if (map.has(r.workspaceMembershipId)) {
      const t = map.get(r.workspaceMembershipId);
      t.push(r);
    } else {
      map.set(r.workspaceMembershipId, [r]);
    }
  }
  return map;
}

export function mapForHasWorkspaceMembershipId<
  T extends hasWorkspaceMembershipId,
>(rows: T[]) {
  const map = new Map<string, T>(rows.map((r) => [r.workspaceMembershipId, r]));
  return map;
}
