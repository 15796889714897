import { useContext } from "react";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { UxContext } from "@/models/UxStateProvider";
import SessionRecorderFooter from "@/components/SessionRecorderFooter/Index";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { VolumeOffOutlined } from "@mui/icons-material";
import ContextualFooter from "@/components/ContextualFooter";

export default function FeedFooter() {
  const { userReadOnlyMode } = useContext(UxContext);
  const { currentFeed, haveWritePermission } = useContext(CurrentFeedContext);
  const { workspaceId, feedId } = useParams();
  const canRecordOrUpload = haveWritePermission;
  const isSilentChannel = currentFeed?.isSilent;

  if (canRecordOrUpload && !userReadOnlyMode) {
    return <SessionRecorderFooter workspaceId={workspaceId} feedId={feedId} />;
  }

  return (
    <ContextualFooter sx={{ height: isSilentChannel ? 64 : 34 }}>
      {userReadOnlyMode
        ? "You are in Read Only mode"
        : "You are a listener in this channel"}
      {isSilentChannel ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <VolumeOffOutlined role="presentation" sx={{ pr: 0.5 }} />
          <Typography
            sx={{ fontSize: "12px", fontStyle: "italic", fontWeight: 500 }}
          >
            Hands-free is off
          </Typography>
        </Box>
      ) : null}
    </ContextualFooter>
  );
}
