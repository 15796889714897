import {
  createManyWsAudioEncoding,
  createManyWsDisplayArtifact,
  createManyWsFile,
  createManyWsItem,
  createManyWsLink,
  createManyWsPAM,
  createManyWsTranscription,
} from "@/data/pg/bulkInserts";
import { upsertManyWsPermission } from "@/data/pg/updates";
import { db } from "@/db/db";
import {
  accountEvent,
  feed,
  item,
  permission,
  workspaceCommandAlias,
  workspaceMembership,
} from "@/db/schema";
import { WorkspaceMembership } from "@/db/types";
import { and, desc, eq, gt, inArray, lte, ne, sql } from "drizzle-orm";
import { GetWorkspaceFeedItemsResponse } from "web-client/api/data-contracts";
import Client from "web-client/client";
import { unreadClearEvents } from "../UnreadsContextProvider";
import { PreferredLanguage } from "../languages";
import { downloadOldestFeedItemEvents } from "./bootstrap";

const BOOTSTRAP_ITEMS_PER_PAGE = 10000;

export async function downloadPaginatedFeedPermissions({
  client,
  workspaceId,
  feedId,
}: { client: Client; workspaceId: string; feedId: string }) {
  let pageSize = 50000;
  let page = 0;
  while (true) {
    const response = await client.getWorkspaceFeedPermissions({
      workspaceId,
      feedId,
      page,
      pageSize,
    });
    if (response.pageSize < pageSize) {
      pageSize = response.pageSize;
    }
    upsertManyWsPermission(response.permissions);
    if (
      response.permissions.length === 0 ||
      response.permissions.length < response.pageSize ||
      page > 1000
    ) {
      break;
    }
    page += 1;
  }
  return await db
    .update(feed)
    .set({ loadedPermissions: true })
    .where(eq(feed.id, feedId))
    .execute();
}

export async function downloadPaginatedBootstrapFeedItems(
  client: Client,
  workspaceId: string,
  pagesToFetch = 100,
  startingPage = 0,
) {
  let page = startingPage;
  const parallelRequests = 1;
  let count = 0;
  let maxPageSize = BOOTSTRAP_ITEMS_PER_PAGE;

  while (page < startingPage + pagesToFetch) {
    const promises: Promise<GetWorkspaceFeedItemsResponse>[] = [];
    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .bootstrapWorkspaceFeedItems(
            workspaceId,
            currentPage,
            BOOTSTRAP_ITEMS_PER_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            createManyWsItem(r.items);
            return r;
          }),
      );
      page += 1;
    }
    const responses = await Promise.all(promises);
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.items?.length,
      0,
    );
    count += currentCount;
    maxPageSize = Math.min(maxPageSize, responses[0].pageSize);

    if (currentCount % maxPageSize !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
  return count;
}

export async function downloadFeedItemContent(
  client: Client,
  workspaceId: string,
  feedItemIds: string[],
  language?: PreferredLanguage,
  timestamp?: string,
) {
  if (feedItemIds.length === 0) return;
  const chunkSize = 40;
  const itemIdChunks = [];
  for (let i = 0; i < feedItemIds.length; i += chunkSize) {
    itemIdChunks.push(feedItemIds.slice(i, i + chunkSize));
  }

  const topPromises = [];

  for (const itemChunk of itemIdChunks) {
    topPromises.push(
      downloadOldestFeedItemEvents(client, itemChunk, workspaceId, timestamp),
    );
    topPromises.push(
      client
        .getFeedItemContent(workspaceId, itemChunk, language, timestamp)
        .then(async (r) => {
          const promises = [];

          promises.push(createManyWsTranscription(r?.transcriptions || []));
          promises.push(createManyWsAudioEncoding(r?.audioEncodings || []));
          promises.push(createManyWsFile(r?.files || []));
          promises.push(createManyWsLink(r?.links || []));
          promises.push(createManyWsDisplayArtifact(r?.displayArtifacts || []));
          promises.push(createManyWsPAM(r?.pam || []));

          return Promise.all(promises);
        }),
    );
  }
  await Promise.all(topPromises);
  await db
    .update(item)
    .set({
      loadedContent: true,
    })
    .where(inArray(item.id, feedItemIds))
    .execute();
}
// DEPRECATED
async function processUnreadItemsForFeed({
  feedId,
  myAccountId,
  workspaceId,
}: {
  feedId: string;
  myAccountId: string;
  workspaceId: string;
}) {
  const myWorkspaceMembership = await db.query.workspaceMembership.findFirst({
    where: and(
      eq(workspaceMembership.workspaceId, workspaceId),
      eq(workspaceMembership.accountId, myAccountId),
    ),
  });

  if (!myWorkspaceMembership) {
    throw new Error("My workspace membership not found");
  }

  return initialUnreadItems({
    feedId,
    myAccountId,
    myCurrentWorkspaceRole: myWorkspaceMembership.role,
    myWorkspaceMembershipId: myWorkspaceMembership.id,
    workspaceId,
  });
}

export async function initialUnreadItems({
  feedId,
  myAccountId,
  myCurrentWorkspaceRole,
  myWorkspaceMembershipId,
  workspaceId,
  initialLoad = false,
}: {
  feedId: string;
  myAccountId: string;
  myCurrentWorkspaceRole: string;
  myWorkspaceMembershipId: string;
  workspaceId: string;
  initialLoad?: boolean;
}) {
  const startTime = new Date().getTime();
  if (
    !feedId ||
    !myAccountId ||
    !myCurrentWorkspaceRole ||
    !workspaceId ||
    !myWorkspaceMembershipId
  ) {
    throw new Error("Missing required parameters");
  }

  const feedRecord = await db.query.feed.findFirst({
    where: eq(feed.id, feedId),
  });

  const isAliasChannel = feedRecord?.isAliasFeed || false;
  const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;

  if (isOrganizer && isAliasChannel) {
    const organizers = await db
      .select({ accountId: workspaceMembership.accountId })
      .from(workspaceMembership)
      .where(
        and(
          eq(workspaceMembership.workspaceId, workspaceId),
          eq(workspaceMembership.role, "member"),
        ),
      )
      .execute();

    const organizerIds = organizers.map((o) => o.accountId);

    const organizerLatestEvent = await db.query.accountEvent
      .findFirst({
        where: and(
          eq(accountEvent.feedId, feedId),
          inArray(accountEvent.accountId, organizerIds),
          inArray(accountEvent.name, unreadClearEvents),
        ),
        orderBy: desc(accountEvent.createdAt),
      })
      .execute();

    const organizerLatestDatesSource = [organizerLatestEvent?.createdAt];

    if (!organizerLatestEvent?.createdAt) {
      const feedReadPermission = await db.query.permission
        .findFirst({
          where: and(
            eq(permission.feedId, feedId),
            eq(permission.workspaceMembershipId, myWorkspaceMembershipId),
            eq(permission.name, "read"),
            eq(permission.enabled, true),
          ),
        })
        .execute();
      organizerLatestDatesSource.push(feedReadPermission?.updatedAt);
    }

    const organizerLatestDates = organizerLatestDatesSource
      .filter((date) => date !== undefined)
      .map((date) => new Date(date))
      .sort((a, b) => b.getTime() - a.getTime());
    // console.log("UNREADS: MARKING AS ORGANIZER", feedId);
    const organizerLatest = organizerLatestDates[0]?.toISOString();
    if (!organizerLatest) return;
    await Promise.all([
      db
        .update(item)
        .set({
          unread: true,
        })
        .where(
          and(
            eq(item.feedId, feedId),
            eq(item.isFromAliasMember, true),
            gt(item.createdAt, organizerLatest),
          ),
        )
        .execute(),
      !initialLoad
        ? db
            .update(item)
            .set({
              unread: false,
            })
            .where(
              and(
                eq(item.feedId, feedId),
                lte(item.createdAt, organizerLatest),
              ),
            )
            .execute()
        : Promise.resolve(),
    ]);
  } else {
    // console.log("UNREADS: MARKING MINE", feedId);
    const myLatestEvent = await db.query.accountEvent
      .findFirst({
        where: and(
          eq(accountEvent.feedId, feedId),
          eq(accountEvent.accountId, myAccountId),
          inArray(accountEvent.name, unreadClearEvents),
        ),
        orderBy: desc(accountEvent.createdAt),
      })
      .execute();

    const myLatestDatesSource = [myLatestEvent?.createdAt];
    if (!myLatestEvent?.createdAt) {
      const feedReadPermission = await db.query.permission
        .findFirst({
          where: and(
            eq(permission.feedId, feedId),
            eq(permission.workspaceMembershipId, myWorkspaceMembershipId),
            eq(permission.name, "read"),
            eq(permission.enabled, true),
          ),
        })
        .execute();
      myLatestDatesSource.push(feedReadPermission?.updatedAt);
    }
    const myLatestDates = myLatestDatesSource
      .filter((date) => date !== undefined)
      .map((date) => new Date(date))
      .sort((a, b) => b.getTime() - a.getTime());

    const myLatest = myLatestDates[0]?.toISOString();
    if (!myLatest) return;
    await Promise.all([
      db
        .update(item)
        .set({
          unread: true,
        })
        .where(
          and(
            eq(item.feedId, feedId),
            ne(item.accountId, myAccountId),
            gt(item.createdAt, myLatest),
          ),
        )
        .execute(),
      !initialLoad
        ? db
            .update(item)
            .set({
              unread: false,
            })
            .where(and(eq(item.feedId, feedId), lte(item.createdAt, myLatest)))
            .execute()
        : Promise.resolve(),
    ]);
  }

  const endTime = new Date().getTime();
  console.log("DONE: Unreads", feedId, endTime - startTime);
}

export async function initializeFromAlias() {
  const rawSQL = sql`
  with items_from_alias as (
    select ${item.id} as id
    from ${item} 
    inner join ${workspaceMembership} 
      on ${item.accountId} = ${workspaceMembership.accountId}
    inner join ${workspaceCommandAlias} 
      on ${item.feedId} = ${workspaceCommandAlias.feedId} 
      and ${workspaceMembership.id} = ${workspaceCommandAlias.workspaceMembershipId}
  )
  update ${item}
  set "isFromAliasMember" = true
  from items_from_alias
  where ${item.id} = items_from_alias.id 
  
  `;

  const startTime = new Date().getTime();
  const results = await db.execute(rawSQL);
  const endTime = new Date().getTime();
  console.log("DONE: INITIAL FROM ALIAS", endTime - startTime);

  return results;
}

export async function initializeFeedLatestActivity() {
  const rawSQL = sql`
  with latest_feed_items as (
    select 
    MAX(${item.createdAt}) as "latestActivity", 
    MAX(${item.createdAt}) FILTER(where ${item.isFromAliasMember} = true) as "latestAliasActivity",
    ${feed.id} as id
    from ${feed}
    inner join ${item} on ${feed.id} = ${item.feedId}
    group by ${feed.id}
  )
  update ${feed}
  set 
  "latestActivity" = latest_feed_items."latestActivity", 
  "latestAliasActivity" = latest_feed_items."latestAliasActivity"

  from latest_feed_items
  where ${feed.id} = latest_feed_items.id 
  
  `;

  const startTime = new Date().getTime();
  const results = await db.execute(rawSQL);
  const endTime = new Date().getTime();
  console.log("DONE: INITIAL FEED LATEST ACTIVITY", endTime - startTime);

  return results;
}

export async function initializeIsAliasFeed() {
  const rawSQL = sql`
  with alias_feed as (
    select ${feed.id} as id
    from ${feed}
    inner join ${workspaceCommandAlias} on ${feed.id} = ${workspaceCommandAlias.feedId}
  )
  update ${feed}
  set "isAliasFeed" = true
  from alias_feed
  where ${feed.id} = alias_feed.id 
  
  `;

  const startTime = new Date().getTime();
  const results = await db.execute(rawSQL);
  const endTime = new Date().getTime();
  console.log("DONE: INITIAL IS ALIAS FEED", endTime - startTime);

  return results;
}

export async function initializeUnreadsForAllFeeds({
  membership,
  feedIds,
  alsoSetAsRead = false,
}: {
  membership: WorkspaceMembership;
  feedIds?: string[];
  alsoSetAsRead: boolean;
}) {
  const clearEventsSql = sql.join(unreadClearEvents, sql`, `);
  const myUnreadAll = sql`
  with feed_latest_event as (
    select 
    ${permission.feedId},
    MAX(${accountEvent.createdAt}) as "latestEvent",
    MAX(${permission.updatedAt}) as "permissionUpdated"

    from ${permission}

    left outer join ${accountEvent} 
    on ${accountEvent.feedId} = ${permission.feedId}
    and ${accountEvent.accountId} = ${membership.accountId}
    and ${accountEvent.name} in (${clearEventsSql})
    
    where ${permission.workspaceMembershipId} = ${membership.id}
    and ${permission.name} = 'read'
    and ${permission.enabled} = true
    ${
      feedIds
        ? sql`and ${permission.feedId} in (${sql.join(feedIds, sql`, `)})`
        : sql``
    }

    group by ${permission.feedId}
  )
  update ${item}
  set "unread" = true
  from feed_latest_event
  where ${item.feedId} = feed_latest_event."feedId"
  and ${item.accountId} != ${membership.accountId}
  and ${item.createdAt} > 
    GREATEST(
      COALESCE(feed_latest_event."latestEvent", '-infinity'::timestamp), 
      COALESCE(feed_latest_event."permissionUpdated", '-infinity'::timestamp)
    )
  `;

  const myReadAll = sql`
  with feed_latest_event as (
    select 
    ${permission.feedId},
    MAX(${accountEvent.createdAt}) as "latestEvent",
    MAX(${permission.updatedAt}) as "permissionUpdated"

    from ${permission}

    left outer join ${accountEvent} 
    on ${accountEvent.feedId} = ${permission.feedId}
    and ${accountEvent.accountId} = ${membership.accountId}
    and ${accountEvent.name} in (${clearEventsSql})
    
    where ${permission.workspaceMembershipId} = ${membership.id}
    and ${permission.name} = 'read'
    and ${permission.enabled} = true
    ${
      feedIds
        ? sql`and ${permission.feedId} in (${sql.join(feedIds, sql`, `)})`
        : sql``
    }

    group by ${permission.feedId}
  )
  update ${item}
  set "unread" = false
  from feed_latest_event
  where ${item.feedId} = feed_latest_event."feedId"
  and ${item.accountId} != ${membership.accountId}
  and ${item.createdAt} <=
    GREATEST(
      COALESCE(feed_latest_event."latestEvent", '-infinity'::timestamp), 
      COALESCE(feed_latest_event."permissionUpdated", '-infinity'::timestamp)
    )
  and ${item.unread} = true
  `;

  const myUnreadsNonAlias = sql`
  with feed_latest_event as (
    select 
    ${permission.feedId},
    MAX(${accountEvent.createdAt}) as "latestEvent",
    MAX(${permission.updatedAt}) as "permissionUpdated"

    from ${permission}

    join ${feed} on ${permission.feedId} = ${feed.id}
    and ${feed.isAliasFeed} = false

    left outer join ${accountEvent} 
    on ${accountEvent.feedId} = ${permission.feedId}
    and ${accountEvent.accountId} = ${membership.accountId}
    and ${accountEvent.name} in (${clearEventsSql})
    
    where ${permission.workspaceMembershipId} = ${membership.id}
    and ${permission.name} = 'read'
    and ${permission.enabled} = true
    ${
      feedIds
        ? sql`and ${permission.feedId} in (${sql.join(feedIds, sql`, `)})`
        : sql``
    }
    group by ${permission.feedId}
  )
  update ${item}
  set "unread" = true
  from feed_latest_event
  where ${item.feedId} = feed_latest_event."feedId"
  and ${item.accountId} != ${membership.accountId}
  and ${item.createdAt} > 
    GREATEST(
      COALESCE(feed_latest_event."latestEvent", '-infinity'::timestamp), 
      COALESCE(feed_latest_event."permissionUpdated", '-infinity'::timestamp)
    )
  `;

  const myReadsNonAlias = sql`
  with feed_latest_event as (
    select 
    ${permission.feedId},
    MAX(${accountEvent.createdAt}) as "latestEvent",
    MAX(${permission.updatedAt}) as "permissionUpdated"

    from ${permission}

    join ${feed} on ${permission.feedId} = ${feed.id}
    and ${feed.isAliasFeed} = false

    left outer join ${accountEvent} 
    on ${accountEvent.feedId} = ${permission.feedId}
    and ${accountEvent.accountId} = ${membership.accountId}
    and ${accountEvent.name} in (${clearEventsSql})
    
    where ${permission.workspaceMembershipId} = ${membership.id}
    and ${permission.name} = 'read'
    and ${permission.enabled} = true
    ${
      feedIds
        ? sql`and ${permission.feedId} in (${sql.join(feedIds, sql`, `)})`
        : sql``
    }
    group by ${permission.feedId}
  )
  update ${item}
  set "unread" = false 
  from feed_latest_event
  where ${item.feedId} = feed_latest_event."feedId"
  and ${item.accountId} != ${membership.accountId}
  and ${item.createdAt} <=
    GREATEST(
      COALESCE(feed_latest_event."latestEvent", '-infinity'::timestamp), 
      COALESCE(feed_latest_event."permissionUpdated", '-infinity'::timestamp)
    )
  and ${item.unread} = true
  `;

  const organizerUnreadsAlias = sql`

  with feed_latest_event as (
    select 
    ${permission.feedId},
    MAX(${accountEvent.createdAt}) as "latestEvent",
    MAX(${permission.updatedAt}) as "permissionUpdated"
    from ${permission}

    join ${feed} 
    on ${permission.feedId} = ${feed.id}
    and ${feed.isAliasFeed} = true
    
    join ${workspaceMembership} 
    on ${workspaceMembership.workspaceId} = ${membership.workspaceId}
    and ${workspaceMembership.role} = 'member'

    left outer join ${accountEvent} 
    on ${accountEvent.feedId} = ${permission.feedId}
    and ${accountEvent.accountId} = ${workspaceMembership.accountId}
    and ${accountEvent.name} in (${clearEventsSql})
    
    where ${permission.workspaceMembershipId} = ${membership.id}
    and ${permission.name} = 'read'
    and ${permission.enabled} = true
    ${
      feedIds
        ? sql`and ${permission.feedId} in (${sql.join(feedIds, sql`, `)})`
        : sql``
    }

    group by ${permission.feedId}
  )
  update ${item}
  set "unread" = true
  from feed_latest_event
  where ${item.feedId} = feed_latest_event."feedId"
  and ${item.accountId} != ${membership.accountId}
  and ${item.createdAt} > 
    GREATEST(
      COALESCE(feed_latest_event."latestEvent", '-infinity'::timestamp), 
      COALESCE(feed_latest_event."permissionUpdated", '-infinity'::timestamp)
    )
  and ${item.isFromAliasMember} = true
  `;

  const organizerReadsAlias = sql`
  with feed_latest_event as (
    select 
    ${permission.feedId},
    MAX(${accountEvent.createdAt}) as "latestEvent",
    MAX(${permission.updatedAt}) as "permissionUpdated"
    from ${permission}

    join ${feed} 
    on ${permission.feedId} = ${feed.id}
    and ${feed.isAliasFeed} = true
    
    join ${workspaceMembership} 
    on ${workspaceMembership.workspaceId} = ${membership.workspaceId}
    and ${workspaceMembership.role} = 'member'

    left outer join ${accountEvent} 
    on ${accountEvent.feedId} = ${permission.feedId}
    and ${accountEvent.accountId} = ${workspaceMembership.accountId}
    and ${accountEvent.name} in (${clearEventsSql})
    
    where ${permission.workspaceMembershipId} = ${membership.id}
    and ${permission.name} = 'read'
    and ${permission.enabled} = true
    ${
      feedIds
        ? sql`and ${permission.feedId} in (${sql.join(feedIds, sql`, `)})`
        : sql``
    }

    group by ${permission.feedId}
  )
  update ${item}
  set "unread" = false
  from feed_latest_event
  where ${item.feedId} = feed_latest_event."feedId"
  and ${item.accountId} != ${membership.accountId}
  and ${item.createdAt} <=
    GREATEST(
      COALESCE(feed_latest_event."latestEvent", '-infinity'::timestamp), 
      COALESCE(feed_latest_event."permissionUpdated", '-infinity'::timestamp)
    )
  and ${item.isFromAliasMember} = true
  and ${item.unread} =true 
  `;
  const startTime = new Date().getTime();

  console.log("UNREADS: STARTING INITIAL UNREADS", { membership });

  try {
    if (membership.role === "member") {
      console.log("UNREADS: UNREADS my non alias");
      await db.execute(myUnreadsNonAlias);
      console.log("UNREADS: UNREADS organizer alias");
      await db.execute(organizerUnreadsAlias);
      if (alsoSetAsRead) {
        console.log("UNREADS: READS my non alias");
        await db.execute(myReadsNonAlias);
        console.log("UNREADS: READS organizer alias");
        await db.execute(organizerReadsAlias);
      }
    } else {
      console.log("UNREADS: UNREADS ALL");
      await db.execute(myUnreadAll);
      if (alsoSetAsRead) {
        console.log("UNREADS: READS ALL");
        await db.execute(myReadAll);
      }
    }
  } catch (e) {
    console.error("ERROR", e);
  }
  const endTime = new Date().getTime();
  if (feedIds?.length > 0) {
    console.log(
      "DONE: UNREADS for feeds",
      membership,
      feedIds,
      endTime - startTime,
    );
  } else {
    console.log("DONE: INITIAL UNREADS ALL", membership, endTime - startTime);
  }
}
