import AvatarWithStatus from "@/components/AvatarWithStatus";
import Locator from "@/locator";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import {
  transformHandsFreeColor,
  transformHandsFreeStatus,
} from "@/utils/handsFree";
import Box from "@mui/material/Box";
import React, { useContext } from "react";

export default function AliasChannelHeader({
  aliasChannel,
}: {
  aliasChannel: { alias: string; title: string; name: string; id: string };
}) {
  const { accountMap } = useContext(WorkspaceContext);
  const accountRecordWithStatus = accountMap.get(aliasChannel.id);
  return (
    <Box
      aria-label={Locator.mainNav.navBar}
      sx={{
        display: "flex",
        justifyContent: "start",
        width: "100%",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ mr: 2 }}>
          <AvatarWithStatus
            size="xm"
            accountId={aliasChannel.id}
            key={aliasChannel.id}
          />
        </Box>
        <Box>
          <Box
            sx={{ fontSize: "18px", mb: "-4px", fontWeight: "bold" }}
            aria-label={Locator.mainNav.displayTitle}
          >
            {aliasChannel.title}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flex: "0 1 auto",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ textTransform: "capitalize" }}>
            {transformHandsFreeStatus(accountRecordWithStatus.status)}
          </Box>
          {accountRecordWithStatus.status === "inactive" && (
            <Box sx={{ ml: 1 }}>{accountRecordWithStatus.lastOnline}</Box>
          )}
        </Box>

        <Box
          sx={{
            width: "12px",
            ml: 1,
            height: "12px",
            borderRadius: "100%",
            background: transformHandsFreeColor(accountRecordWithStatus.status),
          }}
        />
      </Box>
    </Box>
  );
}
