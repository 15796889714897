import { db } from "@/db/db";
import { accountEvent } from "@/db/schema";
import { AccountEvent } from "@/db/types";
import { AccountEventRequest } from "web-client/api/data-contracts";

export async function upsertAccountEvent(e: AccountEventRequest) {
  if (!e) {
    console.log("upsertAccountEvent: no event");
    return;
  }

  const ae: AccountEvent = {
    id: e.id,
    accountId: e.accountId,
    feedId: e.feedId,
    createdAt: e.timestamp,
    contentId: e.contentId,
    itemId: e.feedItemId,
    name: e.name,
  };

  return await db
    .insert(accountEvent)
    .values(ae)
    .onConflictDoNothing()
    .execute();
}
