import { Repl } from '@electric-sql/pglite-repl'
import { client, db } from '@/db/db';
import ModalForm from "@/elements/ModalForm";
import { Box } from '@mui/material';
export default function SqlEditor({
  open,
  close,
}: { open: boolean; close: () => void }) {

  return (
    <ModalForm
      maxWidth={1800}
      maxHeight={1200}
      sx={{
        padding:2,
        height: "calc(100vh - 300px)",
      }}
      open={open}
      onClose={() => close()}
    >
      <Box sx={{width:'100%', height:'100%'}}>
        <Repl pg={client} border={true} theme='dark' />
      </Box>
    </ModalForm>
  );
}
