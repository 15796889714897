import { HandsFreeTypes } from "@/db/types";
import { themeOptions } from "@/theme/theme";

export type TimeDifference = {
  targetDate: Date;
  now: Date;
  diffInMilliseconds: number;
  diffInMinutes: number;
  diffInHours: number;
  diffInDays: number;
  lastOnline: string;
};

export type HandsFreeStatus = {
  status: HandsFreeTypes;
  lastOnline: string;
};
export const plural = (value: number) => (value > 1 ? "s" : "");
export const checkTimestampAgainstDate = (
  timestamp: string,
): TimeDifference => {
  const targetDate = new Date(timestamp);
  const now = new Date();
  const diffInMilliseconds = Math.abs(now.getTime() - targetDate.getTime());
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInMonths / 12);

  let lastOnline = "";
  if (diffInYears > 0) {
    lastOnline = `${diffInYears}y`;
  } else if (diffInMonths > 0) {
    lastOnline = `${diffInMonths}mo`;
  } else if (diffInWeeks > 0) {
    lastOnline = `${diffInWeeks}w`;
  } else if (diffInDays > 0) {
    lastOnline = `${diffInDays}d`;
  } else if (diffInHours > 0) {
    lastOnline = `${diffInHours}h`;
  } else if (diffInMinutes > 0) {
    lastOnline = `${diffInMinutes}m`;
  } else if (diffInSeconds > 0) {
    lastOnline = `${diffInSeconds}s`;
  }

  return {
    targetDate,
    now,
    diffInMilliseconds,
    diffInMinutes,
    diffInHours,
    diffInDays,
    lastOnline,
  };
};

export const calculateHandsFreeStatus = ({
  enabled,
  mostRecentEnabled,
  mostRecentDisabled,
}: {
  enabled: boolean;
  mostRecentEnabled: string | undefined;
  mostRecentDisabled: string | undefined;
}) => {
  let type = {} as TimeDifference;

  if (mostRecentEnabled && !mostRecentDisabled) {
    type = checkTimestampAgainstDate(mostRecentEnabled);
  } else if (!mostRecentEnabled && mostRecentDisabled) {
    type = checkTimestampAgainstDate(mostRecentDisabled);
  } else if (mostRecentEnabled && mostRecentEnabled) {
    type = checkTimestampAgainstDate(mostRecentEnabled);
  }

  const staleCheckOne = type?.diffInMinutes >= 2;
  const staleCheckTwo = type?.diffInMinutes >= 10;

  if (enabled === true && staleCheckOne === false) {
    return {
      status: "active" as HandsFreeTypes,
      lastOnline: type.lastOnline,
    };
  } else if (
    enabled === true &&
    staleCheckOne === true &&
    staleCheckTwo === false
  ) {
    return {
      status: "unknown" as HandsFreeTypes,
      lastOnline: type.lastOnline,
    };
  }

  return {
    status: "inactive" as HandsFreeTypes,
    lastOnline: type.lastOnline,
  };
};

export const transformHandsFreeStatus = (status: HandsFreeTypes) => {
  if (status === "active") {
    return "online";
  } else if (status === "unknown") {
    return "unknown";
  } else {
    return "offline";
  }
};

export const transformHandsFreeColor = (status: HandsFreeTypes) => {
  if (status === "active") {
    // @ts-ignore
    return themeOptions.palette.brand.other.green.main;
  } else if (status === "unknown") {
    // @ts-ignore
    return themeOptions.palette.warning.main;
  } else {
    // @ts-ignore
    return themeOptions.palette.secondary.main;
  }
};
