import { useDrizzleSelect } from "@/db/drizzleUtils";
import { Item } from "@/db/types";
import { createContext, useContext } from "react";
import type { AccountEventType } from "web-client/api/data-contracts";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import { unreadFeedIdsQuery } from "./commonQueries";

type UnreadFeed = {
  feedId: string;
  unreadItems: Array<Item>;
};

export const unreadClearEvents: AccountEventType[] = [
  "Saw Feed Item",
  "Marked Feed as Read",
  "Opened Feed",
  "Started Listening to Feed Item", // used for in-line feed views
];

type UnreadsState = {
  allUnreadFeeds?: Array<UnreadFeed>;
  unreadFeedIds?: Array<string>;
};

export const UnreadsContext = createContext<UnreadsState>({
  allUnreadFeeds: [],
});

const UnreadsContextProvider = ({ children }) => {
  const { myCurrentWorkspaceMembership, currentWorkspaceId } =
    useContext(WorkspaceContext);

  const { rows: unreadFeedIds } = useDrizzleSelect(
    unreadFeedIdsQuery({
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
  );

  const unreadsState: UnreadsState = {
    unreadFeedIds: unreadFeedIds?.map((ufi) => ufi.feedId),
  };

  return (
    <UnreadsContext.Provider value={unreadsState}>
      {children}
    </UnreadsContext.Provider>
  );
};

export default UnreadsContextProvider;
